import React from 'react';

const LoadingChat = () => {
    return (
        <div className="bg-chat-botBG p-4 loading-dots flex items-center space-x-1 rounded-md">
            <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg" className="dot animate-pulse">
                <circle cx="2" cy="2" r="2" fill="#9A9A9A" />
            </svg>
            <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg" className="dot animate-fade">
                <circle cx="2" cy="2" r="2" fill="#9A9A9A" />
            </svg>
            <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg" className="dot animate-fade">
                <circle cx="2" cy="2" r="2" fill="#9A9A9A" />
            </svg>
        </div>
    );
};

export default LoadingChat;
