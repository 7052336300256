import React, { useEffect, useState } from 'react';

const ConsentScreen = ({ isConsentChecked, setIsConsentChecked, handleBeginChat, organization }) => {
    const [isLoading, setIsLoading] = useState(true);

    // Monitor the `organization` prop to determine when it's loaded
    useEffect(() => {
        if (organization) {
            setIsLoading(false);  // Disable loading state once organization is available
        }
    }, [organization]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <p className="text-xl font-bold">Loading...</p>
            </div>
        );
    }

    return (
        <div className="relative space-y-6 p-6 mt-8 md:mt-12 sm:mt-8 mb-16 bg-white rounded-lg shadow-md mx-auto w-full">
            <div className='mt-6'>
                <h1 className='font-extrabold text-2xl mt-6'>Welcome!</h1>

                <p className='text-xl mt-6 mb-2'>Welcome to this research study.</p>

                <p className='mt-6 mb-2'>On behalf of <span className='font-extrabold'>{organization}</span>, we thank you for agreeing to participate!</p>

                <p className='mt-6 mb-2'>The activity shouldn't take longer than <span className='font-extrabold'>10 to 15 minutes to complete</span>. Your response will help us to better understand how people perceive the various contents of our website.</p>

                <h3 className='font-extrabold text-2l mt-6'>To continue we need your consent to process personal data</h3>

                <p className='mt-6 mb-2'>Canary insights may process your personal data within this study on behalf of the Data Controller, <span className='font-extrabold'>{organization}</span> in accordance with Canary insights's <a href="#">Terms of Service</a> and the <a href="#">Privacy Policy</a>.</p>

                <p className='mt-6 mb-2'>Canary insights doesn't record or process sensitive information such as passwords, credit card numbers, etc. Canary insights will never provide your data to third parties.</p>
                <p className='mt-6 mb-2'>Please give us your consent to collect the following personal data:</p>

                <div className="mt-4">
                    <input
                        type="checkbox"
                        id="consent"
                        checked={isConsentChecked}
                        onChange={(e) => setIsConsentChecked(e.target.checked)}  // Update checkbox state only
                    />
                    <label htmlFor="consent" className="ml-2">I agree to the terms and conditions</label>
                </div>
            </div>
            <button
                className={`bg-canary z-0 hover:bg-yellow-600 text-black py-2 px-4 rounded-full ${!isConsentChecked ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleBeginChat}
                disabled={!isConsentChecked}  // Button is only enabled when consent is checked
            >
                Begin Chat
            </button>
        </div>
    );
};

export default ConsentScreen;
