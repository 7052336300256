import React, { useState } from 'react';

function InterviewScriptBuilder({ projectId, createChatbot, history }) {
    const [introduction, setIntroduction] = useState('');
    const [objective, setObjective] = useState('');
    const [topics, setTopics] = useState('');
    const [questions, setQuestions] = useState(['']); // Start with one question input
    const [prototypeUrl, setPrototypeUrl] = useState('');
    const [prototypeType, setPrototypeType] = useState('');

    // Function to handle adding new question input fields
    const addQuestion = () => {
        setQuestions([...questions, '']); // Add an empty string for new question
    };

    // Function to handle updating the text of a specific question
    const updateQuestion = (index, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index] = value;
        setQuestions(updatedQuestions);
    };

    // Function to handle deleting a specific question
    const handleDeleteQuestion = (index) => {
        const updatedQuestions = [...questions];
        updatedQuestions.splice(index, 1); // Remove the question at the specified index
        setQuestions(updatedQuestions);
    };

    // Handle form submission and chatbot creation
    const handleCreateChatbot = async () => {
        try {
            // Combine introduction, objective, topics, and questions into a script
            const script = [
                `Introduction: ${introduction}`,
                `The objective is: ${objective}`,
                `The topics of the interview are: ${topics}`,
                ...questions.map((question, index) => `${index + 1}: ${question}`)
            ].join('\n\n');
            
            console.log('Checking Script is created correctly', script);

            // Create chatbot by passing both the script and separate fields
            await createChatbot(script, introduction, objective, topics, questions, prototypeUrl, prototypeType);
            history.push(`/projects/${projectId}/detail`);
        } catch (error) {
            console.error('Failed to create a new chatbot:', error);
        }
    };

    return (
        <div>
            <label>Introduction:</label>
            <textarea 
                value={introduction} 
                onChange={(e) => setIntroduction(e.target.value)} 
                className='block w-full mb-4 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          
            />

            <label>Objective:</label>
            <textarea 
                value={objective} 
                onChange={(e) => setObjective(e.target.value)} 
                className='block w-full mb-4 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          
            />

            <label>Topics:</label>
            <textarea 
                value={topics} 
                onChange={(e) => setTopics(e.target.value)} 
                className='block w-full mb-4 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          
            />

            <label>Questions:</label>
            {questions.map((question, index) => (
                <div key={index} className='question-input'>
                    <input
                        type="text"
                        value={question}
                        onChange={(e) => updateQuestion(index, e.target.value)}
                        placeholder={`Question ${index + 1}`}
                        className='block w-full mb-4 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          
                    />
                    <button 
                        onClick={() => handleDeleteQuestion(index)} 
                        className='btn btn-danger'>
                        Delete Question
                    </button>
                </div>
            ))}

            <button onClick={addQuestion} className='btn btn-secondary'>Add Another Question</button>

            <br />

            <button onClick={handleCreateChatbot} className='btn btn-primary'>Create Chatbot</button>
        </div>
    );
}

export default InterviewScriptBuilder;
