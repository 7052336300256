import React, { useEffect } from "react";
import axios from 'axios';
import { connect } from 'react-redux';

const StripeSuccess = ({auth, history}) => {

    useEffect(() => {
        const getSubscriptionStatus = async () => {
            const {data} = await axios.get('/api/subscription-status')
                console.log('Subscription status =>', data);
            if(data && data.length === 0){
                history.push("/pricing")
            }else {
                
                history.push("/dashboard")
            }
        }

        getSubscriptionStatus();
    })

    if (!auth) {
        return <div>Please log in</div>;
    }


    return (
        <div> 
            <div>
                <p>Loading...</p>
            </div>
        </div>
    )
}
const mapStateToProps = ({ auth }) => {
    return {auth};
};

export default connect(mapStateToProps)(StripeSuccess);