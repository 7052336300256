import axios from 'axios';
import { FETCH_USER } from './types';
import { UPDATE_USER } from './types';
import {RESET_INTERVIEW_DATA} from './types'
import {SET_INTERVIEW_DATA} from './types'
import {UPDATE_SUBSCRIPTION} from './types'

export const fetchUser = () => async dispatch => {
  const res = await axios.get('/api/current_user');

  dispatch({ type: FETCH_USER, payload: res.data });
};

export const handleToken = token => async dispatch => {
  axios.post('/api/checkout', token).then(res => {
    dispatch({ type: FETCH_USER, payload: res.data });
  }).catch(error => {
    console.log('Error in handleToken ::: ', error);
  });
};

// New action creator
export const updateUser = (userData) => {
  return {
    type: UPDATE_USER,
    payload: userData
  };
};

export const updateCredits = (credits) => async dispatch => {
  dispatch({ type: UPDATE_USER, payload: { credits } });
};


export const resetInterviewData = () => {
  return {
    type: RESET_INTERVIEW_DATA,
  };
};

export const setInterviewData = (data) => {
  return {
    type: SET_INTERVIEW_DATA,
    payload: data,
  };
};

export const fetchInterviewData = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/interview');
    dispatch(setInterviewData(response.data));
  } catch (error) {
    // Handle error
  }
};

export const updateSubscription = (subscriptionData) => {
  return {
    type: UPDATE_SUBSCRIPTION,
    payload: subscriptionData
  };
};

