import React, { useState, useEffect } from 'react';
import axios from 'axios';
import InterviewScriptBuilder from './InterviewScriptBuilder';

function ChatbotCreationForm({ history, match }) {
    const projectId = match.params.projectId;
    const [name, setName] = useState('');
    const [forms, setForms] = useState([]); // State to hold forms data
    const [selectedPreForm, setSelectedPreForm] = useState(''); // State for selected pre-interview form
    const [selectedPostForm, setSelectedPostForm] = useState(''); // State for selected post-interview form

    useEffect(() => {
        // Fetch forms on component mount
        const fetchForms = async () => {
            try {
                const response = await axios.get('/api/forms');
                setForms(response.data);
            } catch (error) {
                console.error('Failed to fetch forms:', error);
            }
        };
        fetchForms();
    }, []);

    const createChatbot = async (script, introduction, objective, topics, questions, prototypeUrl, prototypeType) => {
        try {
            const data = {
                name,
                script,              // Send the combined script
                introduction,        // Send introduction separately
                objective,           // Send objective separately
                topics,              // Send topics separately
                questions,           // Send array of questions
                prototypeUrl,
                prototypeType,
                preInterviewFormId: selectedPreForm || undefined, 
                postInterviewFormId: selectedPostForm || undefined 
            };
            const response = await axios.post(`/api/projects/${projectId}/chatbots`, data);
            console.log('New chatbot created:', response.data);
            history.push(`/projects/${projectId}/detail`);
        } catch (error) {
            console.error('Failed to create a new chatbot:', error.response.data);
        }
    };
    
    

    return (
        <div className='container mx-auto z-0 relative pt-12 mb-12'>
            <h2 className='text-2xl text-black mb-4'>Create New Chatbot</h2>
            <label>Name:</label>
            <input 
                type="text" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                className='block w-full mb-4 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            />

            {/* Dropdown for selecting Pre-Interview Form */}
            <label>Pre-Interview Form:</label>
            <select
                value={selectedPreForm}
                onChange={(e) => setSelectedPreForm(e.target.value)}
                className='block w-full mb-4 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            >
                <option value="">Select a Form</option>
                {forms.filter(form => form.formType === 'pre').map(form => (
                    <option key={form._id} value={form._id}>{form.name}</option>
                ))}
            </select>

            {/* Dropdown for selecting Post-Interview Form */}
            <label>Post-Interview Form:</label>
            <select
                value={selectedPostForm}
                onChange={(e) => setSelectedPostForm(e.target.value)}
                className='block w-full mb-4 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            >
                <option value="">Select a Form</option>
                {forms.filter(form => form.formType === 'post').map(form => (
                    <option key={form._id} value={form._id}>{form.name}</option>
                ))}
            </select>

            <InterviewScriptBuilder
                projectId={projectId}
                createChatbot={createChatbot}
                history={history}
            />
        </div>
    );
}

export default ChatbotCreationForm;
