// Header.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Payments from './Payments';
import Dropdown from './Dropdown';
import Logo from './icons/logo';

class Header extends Component {
  // Function to get initials from the user's name
  getInitials(firstName, lastName) {
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }

  renderContent() {
    const { auth } = this.props;

    switch (auth) {
      case null:
        return;
      case false:
        return [
          <li key="login" className="rounded-lg px-2 text-slate-700 font-medium hover:bg-slate-100 hover:text-slate-900">
            <a href="/"></a>
          </li>
        ];
      default:
        return [
          <li key="1" className="profile-icon">
            <Link to="/profile">
              {auth.profilePic ? (
                <img src={auth.profilePic} alt="Profile" className="profile-picture inline-block h-8 w-8 rounded-full ring-2 ring-white" />
              ) : (
                <div className="fallback-header-profile-picture">
                  {this.getInitials(auth.firstName, auth.lastName)}
                </div>
              )}
            </Link>
          </li>
        ];
    }
  }

  render() {
    const { location } = this.props;

    // Define routes where the header should not be shown
    const noHeaderRoutes = ['/login', '/register', '/onboarding', '/signup'];
    const hideHeaderOnChat = /^\/chat\/[a-zA-Z0-9]+$/;

    // Check if the current route is in the noHeaderRoutes array or matches the chat route pattern
    if (noHeaderRoutes.includes(location.pathname) || hideHeaderOnChat.test(location.pathname)) {
      return null; // Do not render the header
    }

    return (
      <nav className="flex flex-row m-header rounded-full w-header sm:justify-center space-x-4 drop-shadow-md bg-BGGrey-HEADER p-2 z-99">
        <div className="flex flex-row sm:justify-left space-x-4 w-full pl-12 pb-1 text-white align-middle">
          <Link
            to={this.props.auth ? '/projects' : '/'}
            className="rounded-lg px-2 py-2 text-slate-700 font-medium hover:bg-slate-100 hover:text-slate-900"
          >
          <Logo/>

          </Link>
          <div className='w-full pt-3 pr-12 pl-4'>
            <div className='float-left pt-1'>
              <Link className='px-2' to={this.props.auth ? '/projects' : '/'}>Projects</Link>
              <Link className='px-2' to={this.props.auth ? '/practice-sessions' : '/'}>Practice</Link>
              <Link className='px-2' to={'/Pricing'}>Pricing</Link>
            </div>
            <div className='float-right'>
              <ul className="flex flex-row sm:justify-left float-left rounded-lg text-slate-700 font-medium hover:bg-slate-100 hover:text-slate-900">
                {this.renderContent()}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default withRouter(connect(mapStateToProps)(Header));
