import React from "react";
import { connect } from 'react-redux';

const StripeCancel = ({auth}) => {

    if (!auth) {
        return <div>Please log in</div>;
    }


    return (
        <div> 
            <div>
                <p>Transaction cancelled</p>
            </div>
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    return { auth };
};
  export default connect(mapStateToProps)(StripeCancel);