import axios from 'axios';

export const useAxiosClient = () => {
    const client = () => {
        return axios.create({
            baseURL: '/api/'
        });
    }

    return { client }
}