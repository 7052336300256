// PersonaDetail.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useHistory } from 'react-router-dom';

function PersonaDetail() {
    const { personaId } = useParams();
    const [persona, setPersona] = useState(null);
    const [error, setError] = useState(null);
    const history = useHistory();

    useEffect(() => {
        axios.get(`/api/personas/find/${personaId}`)
            .then(response => {
                setPersona(response.data);
                console.log('Persona data:', response.data);
            })
            .catch(error => {
                console.error('Failed to fetch persona details:', error);
                setError('Failed to fetch persona details. Please try again later.');
            });
    }, [personaId]);

    const handleDelete = async () => {
        try {
            await axios.delete(`/api/interviews/personas/${personaId}`);
            history.push(`/interviews/${persona.interviewId}`); // Redirect to interview detail page
        } catch (error) {
            console.error('Failed to delete persona:', error);
            setError('Failed to delete persona. Please try again later.');
        }
    };
    


    if (error) {
        return <div>{error}</div>;
    }

    if (!persona) {
        return <div>Loading persona details...</div>;
    }

    return (
        <div className="container mx-auto">
            <div className="persona-detail">
                <Link to={`/interviews/${persona.interviewId}`}>Back to Interview</Link>
                <h1>{persona.name}</h1>
                <p><strong>Age:</strong> {persona.attributes?.age}</p>
                <p><strong>Location:</strong> {persona.attributes?.location}</p>
                <p><strong>Profession:</strong> {persona.attributes?.profession}</p>
                <p><strong>Diagnosis:</strong> {persona.attributes?.diagnosis ? 'Yes' : 'No'}</p>
                <div>
                    <h3>Decisions</h3>
                    <ul>
                        {persona.choices?.map((choice, index) => (
                            <li key={index}>{choice.choice}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h3>Challenges</h3>
                    <ul>
                        {persona.conflict?.map((conflict, index) => (
                            <li key={index}>{conflict.conflict}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h3>Goals</h3>
                    <ul>
                        {persona.objective?.map((goal, index) => (
                            <li key={index}>{goal.goal}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h3>Obstacles</h3>
                    <ul>
                        {persona.obstacles?.map((obstacle, index) => (
                            <li key={index}>{obstacle.obstacle}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h3>Network</h3>
                    <ul>
                        {persona.network?.map((role, index) => (
                            <li key={index}>{role.details}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h3>Ideas</h3>
                    <ul>
                        {persona.ideas?.map((idea, index) => (
                            <li key={index}>{idea.idea}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h3>Quotes</h3>
                    <ul>
                        {persona.quotes?.map((quote, index) => (
                            <li key={index}>{quote.quote}</li>
                        ))}
                    </ul>
                </div>
                <button onClick={handleDelete} className="mt-4 text-red-600">Delete Persona</button>
            </div>
        </div>
    );
}

export default PersonaDetail;
