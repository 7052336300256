import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

const AnalyzeTranscript = ({ auth }) => {
  const [files, setFiles] = useState([]);
  const [selectedFramework, setSelectedFramework] = useState('');
  const [transcriptIds, setTranscriptIds] = useState([]);
  const [responseJson, setResponseJson] = useState(null);
  const [loading, setLoading] = useState(false);

  if (!auth) {
    return <div>Loading...</div>;
  }

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleFrameworkChange = (event) => {
    setSelectedFramework(event.target.value);
  };

  const uploadTranscripts = async () => {
    setLoading(true);
    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('transcripts', file);
    });

    try {
      const response = await axios.post('/api/upload-transcript', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setTranscriptIds(response.data.map(t => t._id));  // Store transcript IDs from the response
      setLoading(false);
      alert('Transcripts uploaded successfully. Ready to generate persona.');
    } catch (error) {
      console.error('Upload failed:', error);
      setLoading(false);
      alert('Failed to upload transcripts');
    }
  };

  const generatePersona = async () => {
    if (!transcriptIds.length) {
      alert('No transcripts uploaded or selected');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post('/api/generate-framework', { transcriptIds }, {
        headers: {
            'Content-Type': 'application/json',
        },
    });    
      console.log('resonse: ', response)   
      setResponseJson(response.data);
      
      setLoading(false);
      alert('Persona generated successfully. Review below.');
    } catch (error) {
      console.error('Failed to generate persona:', error);
      setLoading(false);
      alert('Failed to generate persona');
    }
  };

  const savePersona = async () => {
    if (!responseJson || !transcriptIds.length) {
      alert('No persona to save or no transcripts linked');
      return;
    }
  
    const personaData = {
      ...responseJson,
      transcriptIds  // Include transcript IDs to be sent to the backend
    };
  
    try {
      const response = await axios.post('/api/save-persona', personaData, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      alert('Persona saved successfully!');
    } catch (error) {
      console.error('Failed to save persona:', error);
      alert('Failed to save persona');
    }
  };
  return (
    <div>
      <form onSubmit={e => e.preventDefault()}>
        <div>
          <input type="file" multiple onChange={handleFileChange} />
        </div>
        <div>
          <button type="button" onClick={uploadTranscripts}>Upload Transcripts</button>
        </div>
        <div>
          <select value={selectedFramework} onChange={handleFrameworkChange}>
            <option value="">Select a Framework</option>
            <option value="persona">Persona</option>
            <option value="customerJourney">Customer Journey</option>
            <option value="valueProp">Value Proposition</option>
          </select>
        </div>
        <button type="button" onClick={generatePersona}>Generate Persona</button>
        {responseJson && <button type="button" onClick={savePersona}>Save Persona</button>}
      </form>
      {loading ? <p>Loading...</p> : (
        responseJson && (
          <div className="result-section">
            <h3>Persona</h3>
            <DisplayPersona persona={responseJson} />
          </div>
        )
      )}
    </div>
  );
};

const Avatar = ({ name, age }) => {
  const seed = encodeURIComponent(`${name}`); // Combining name and age as seed
  const avatarUrl = `https://api.dicebear.com/8.x/lorelei/svg/?${seed}`;

  return (
    <img src={avatarUrl} alt={`${name}'s Avatar`} style={{ width: '100px', height: '100px' }} />
  );
};

const DisplayPersona = ({ persona }) => (
  <div className="persona-details">
    <h2>Persona Analysis Results</h2>
    <Avatar name={persona.name} age={persona.attributes.age} />
    <Section title="Name" content={persona.name} />
    <AttributesSection attributes={persona.attributes} />
    <ListSection title="Decisions" items={persona.choices} field="choice" />
    <ListSection title="Challenges" items={persona.conflict} field="conflict" />
    <ListSection title="Goals" items={persona.objective} field="goal" />
    <ListSection title="Obstacles" items={persona.obstacles} field="obstacle" />
    <NetworkSection title="Network" items={persona.network} />
    <ListSection title="Ideas" items={persona.ideas} field="idea" />
    <ListSection title="Quotes" items={persona.quotes} field="quote" />
  </div>
);

const Section = ({ title, content }) => (
  <div>
    <h4>{title}:</h4>
    <p>{content}</p>
  </div>
);

const ListSection = ({ title, items, field }) => (
  <div>
    <h4>{title}:</h4>
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          {item[field]}
        </li>
      ))}
    </ul>
  </div>
);

const NetworkSection = ({ title, items }) => (
  <div>
    <h4>{title}:</h4>
    <ul>
      {items.map((networkItem, index) => (
        <li key={index}>
          {networkItem.roles.map((role, roleIndex) => (
            <div key={roleIndex}>{role.details}</div>
          ))}
        </li>
      ))}
    </ul>
  </div>
);

// Custom section for displaying persona attributes
const AttributesSection = ({ attributes }) => (
  <div>
    <h4>Attributes:</h4>
    <p>Age: {attributes.age}</p>
    <p>Location: {attributes.location}</p>
    <p>Profession: {attributes.profession}</p>
    <p>Diagnosis: {attributes.diagnosis ? 'Yes' : 'No'}</p>
  </div>
);

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps)(AnalyzeTranscript);
