import React from 'react';

const SendIcon = ({ width = "118", height = "24", fillColor = "#FFFFFF" }) => {
    return (
        <div className=''>
            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3706 10.5932C18.402 10.5011 18.4061 10.4026 18.3812 10.3086C17.7873 8.08295 6.76293 1.75631 3.29869 0.69261C2.39623 0.415407 1.7423 0.527031 1.35838 1.02341C0.245592 2.46008 2.54368 6.9275 3.88487 9.26349L10.8328 9.50752C11.2354 9.52162 11.5504 9.86034 11.5363 10.2641C11.5223 10.6679 11.1845 10.9838 10.7819 10.9697L3.76734 10.7226C2.25764 12.9891 -0.250288 17.1758 0.74419 18.6628C0.781455 18.7186 0.822276 18.7711 0.8667 18.8188C1.23403 19.2139 1.83574 19.3178 2.65611 19.1278C6.18375 18.311 17.6219 12.7745 18.3706 10.5932Z" fill="#0C0D0E"/>
            </svg>
        </div>
    )
   
};

export default SendIcon;

