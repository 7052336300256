// app.js
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Headerapp from './Header';
import Profile from './Profile';
import Landing from './Landing';
import Pricing from './Pricing';
import Register from './Register';
import StripeSuccess from './stripe-success';
import StripeCancel from './stripe-cancel';
import AnalyzeTranscript from './AnalyzeTranscript';
import InterviewSetup from './interviewer/InterviewSetup';
import ProjectCreation from './interviewer/ProjectCreation';
import ProjectDashboard from './interviewer/ProjectDashboard';
import ChatSession from './interviewer/ChatSession';
import ProjectDetail from './interviewer/ProjectDetail';
import InterviewCompletion from './interviewer/InterviewCompletion';
import ChatbotCreationForm from './interviewer/ChatbotCreationForm'; // Import the new component
import InterviewDetail from './interviewer/InterviewDetail';
import PersonaDetail from './interviewer/PersonaDetail';
import PracticeSessionsPage from './interviewer/PracticeSessionsPage';
import SimulatedInterviewPage from './interviewer/SimulatedInterview';
import SimulatedInterviewDetailPage from './interviewer/SimulatedInterviewResults';
import OnboardingForm from './OnboardingForm';
import LoginPage from './Login';
import SignUpPage from './signup';
import Alert from './Alert';

class App extends Component {
  state = {
    error: '',
    loading: true
  };

  async componentDidMount() {
    try {
      await this.props.fetchUser();
    } catch (error) {
      console.error('Failed to fetch user:', error);
      this.setState({ error: 'Failed to fetch user data. Please check your connection.' });
    } finally {
      this.setState({ loading: false });
    }
  }

  handleCloseAlert = () => {
    this.setState({ error: '' });
  };

  render() {
    const { error, loading } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return (
        <div className="container mx-auto p-4 ">
          <Alert message={error} type="error" onClose={this.handleCloseAlert} />
          <button
            onClick={() => (window.location.href = '/login')}
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
          >
            Go to Login
          </button>
        </div>
      );
    }

    return (
      <div className="relative flex min-h-screen flex-col overflow-hidden bg-BGGrey">
        <BrowserRouter>
          <div className="">
            <Headerapp />
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route path="/profile" component={Profile} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/register" component={Register} />
              <Route path="/loginX250821" component={LoginPage} />
              <Route path="/signupX250821" component={SignUpPage} />
              <Route path="/onboarding" component={OnboardingForm} />
              <Route path="/stripe/cancel" component={StripeCancel} />
              <Route path="/stripe/success" component={StripeSuccess} />
              <Route path="/analyze-transcript" component={AnalyzeTranscript} />
              <Route path="/project/create" component={ProjectCreation} />
              <Route exact path="/projects" component={ProjectDashboard} />
              <Route path="/create-interview" component={InterviewSetup} />
              <Route path="/projects/:projectId/detail" component={ProjectDetail} />
              <Route path="/chat/:interviewId" component={ChatSession} />
              <Route path="/chat/:interviewId/:sessionId" component={ChatSession} />
              <Route path="/chat/interview-complete" component={InterviewCompletion} />
              <Route path="/projects/:projectId/chatbot/create" component={ChatbotCreationForm} />
              <Route path="/interviews/:interviewId" component={InterviewDetail} />
              <Route path="/personas/:personaId" component={PersonaDetail} />
              <Route path="/simulated-interview-detail/:sessionId" component={SimulatedInterviewDetailPage} />
              <Route path="/simulated-interview/interview/:sessionId" component={SimulatedInterviewPage} />
              <Route path="/practice-sessions" component={PracticeSessionsPage} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

// Map the auth state to props
function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, actions)(App);
