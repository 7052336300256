// LoadingBar.js
import React from 'react';

const LoadingBar = ({ progress }) => (
    <div style={styles.container}>
        <div style={{ ...styles.loadingBar, width: `${progress}%` }}></div>
    </div>
);

const styles = {
    container: {
        width: '100%',
        height: '4px',
        backgroundColor: '#e0e0e0', // Light gray background
        overflow: 'hidden',
        borderRadius: '2px',
        marginTop: '20px',
    },
    loadingBar: {
        height: '100%',
        backgroundColor: '#0073e6', // Blue color
        transition: 'width 0.3s ease-in-out', // Smooth transition effect
        borderRadius: '2px',
    },
};

export default LoadingBar;
