import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

function PracticeSessionsPage() {
    const [sessions, setSessions] = useState([]);
    const [topic, setTopic] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();

    useEffect(() => {
        axios.get('/api/simulated-interviews')
            .then(response => setSessions(response.data))
            .catch(error => console.error('Failed to fetch practice sessions:', error));
    }, []);

    const startNewSession = async () => {
        if (!topic) {
            setError('Topic is required');
            return;
        }

        setLoading(true);
        setError('');

        try {
            const response = await axios.post('/api/simulated-interviews/start', { topic });
            history.push(`/simulated-interview/interview/${response.data.sessionId}`);
        } catch (error) {
            console.error('Failed to start new session:', error);
            setError('Failed to start new session. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto">
            <h1 className="text-2xl font-bold">Practice Sessions</h1>
            <div className="mt-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="topic">
                    Topic
                </label>
                <input
                    type="text"
                    id="topic"
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <button 
                    onClick={startNewSession} 
                    className="bg-blue-500 text-white py-2 px-4 rounded mt-4"
                    disabled={loading}
                >
                    {loading ? 'Starting...' : 'Start Simulated Interview'}
                </button>
                {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
            <h2 className="text-xl font-semibold mt-8">Completed Sessions</h2>
            <ul>
                {sessions.map(session => (
                    <li key={session._id}>
                        <Link to={`/simulated-interview-detail/${session._id}`}>
                            {new Date(session.date).toLocaleString()} - {session.topic}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default PracticeSessionsPage;
