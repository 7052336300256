import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useAuthApi } from "../../hooks/useAuthApi";
import ProjectCard from './ProjectCard';
import Modal from '../Modal'; // Import the Modal component
import Alert from '../Alert';

function ProjectDashboard({ auth }) {
    const [projects, setProjects] = useState([]);
    const [newProjectName, setNewProjectName] = useState('');
    const [organization, setOrganization] = useState(''); // New state for organization
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const { getCurrentUser } = useAuthApi();

    useEffect(() => {
        const fetchProjects = async () => {
            const response = await axios.get('/api/projects');
            setProjects(response.data);
        };
        fetchProjects();
    }, []);

    const handleCreateProject = async () => {
        try {
            const response = await axios.post('/api/projects', { name: newProjectName, organization });
            if (response.status === 403 && response.data.canUpgrade) {
                setAlertMessage('You have reached your limit for creating new projects. Please upgrade your subscription to continue.');
                setIsAlertOpen(true);
                return;
            }
            setProjects([...projects, response.data]);
            setNewProjectName('');
            setOrganization('');
            setError(null);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Failed to create project:', error.response.data.error);
            setError(error.response.data.error);
        }
    };

    return (
        <div className='container mx-auto z-0 relative pt-12'>
            {isAlertOpen && (
                <Modal isOpen={isAlertOpen} onClose={() => setIsAlertOpen(false)}>
                    <Alert
                        message={alertMessage}
                        type="error"
                        onClose={() => setIsAlertOpen(false)}
                    />
                </Modal>
            )}
            <div className='w-full block relative'>
                <div className='float-left'>
                    <h1 className='text-2xl text-black'>Your Projects</h1>
                </div>
                
                <div className='mt-2 mb-2 float-right'>
                    <button 
                    onClick={() => setIsModalOpen(true)}
                    className="bg-canary z-0 hover:bg-yellow-600 text-black py-2 px-4 rounded-full"
                    >
                        Create Project
                    </button>
                    {error && <div>Error: {error}</div>}
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <h2 className='text-xl font-bold mb-4'>Create New Project</h2>
                <input
                    type="text"
                    placeholder="Enter project name"
                    value={newProjectName}
                    onChange={(e) => setNewProjectName(e.target.value)}
                    className="block w-full mb-4 p-2 border border-gray-300 rounded-md"
                />
                <input
                    type="text"
                    placeholder="Enter organization name"
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                    className="block w-full mb-4 p-2 border border-gray-300 rounded-md"
                />
                <button onClick={handleCreateProject} className="bg-canary z-0 hover:bg-yellow-600 text-black py-2 px-4 rounded-full">
                    Create Project
                </button>
            </Modal>
            <div className="project-cards grid grid-cols-4 grid-flow-col gap-4 mt-4 w-full clear-both">
                {projects.map(project => (
                    <ProjectCard key={project._id} project={project} />
                ))}
            </div>
        </div>
    );
}

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default connect(mapStateToProps)(ProjectDashboard);
