import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function SimulatedInterviewPage() {
    const { sessionId } = useParams();
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(`/api/simulated-interviews/${sessionId}`)
            .then(response => {
                setMessages(response.data.messages);
                setLoading(false);
            })
            .catch(error => {
                setError('Failed to fetch messages');
                setLoading(false);
            });
    }, [sessionId]);

    const sendMessage = async () => {
        if (input.trim() === '') return;

        const newMessage = {
            text: input,
            sender: 'user',
            createdAt: new Date()
        };

        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInput('');

        try {
            const response = await axios.post(`/api/simulated-interviews/${sessionId}/message`, { text: input });
            const newAiMessage = response.data.messages[0];
            setMessages(prevMessages => [...prevMessages, newAiMessage]);
        } catch (error) {
            setError('Failed to send message');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="container mx-auto">
            <h1 className="text-2xl font-bold">Simulated Interview</h1>
            <div className="chat-box">
                {messages.length === 0 ? (
                    <p>No messages yet. Start the conversation!</p>
                ) : (
                    messages.map((msg, index) => (
                        <div key={index} className={`message ${msg.sender}`}>
                            <p>{msg.text}</p>
                        </div>
                    ))
                )}
            </div>
            <div className="flex mt-4">
                <input 
                    type="text" 
                    value={input} 
                    onChange={(e) => setInput(e.target.value)} 
                    className="border rounded py-2 px-4 flex-grow" 
                />
                <button 
                    onClick={sendMessage} 
                    className="bg-blue-500 text-white py-2 px-4 rounded ml-2"
                >
                    Send
                </button>
            </div>
        </div>
    );
}

export default SimulatedInterviewPage;
