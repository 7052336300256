// InterviewCompletion.js

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function InterviewCompletion() {
    const history = useHistory();

    // Redirect to home page after a brief delay
    useEffect(() => {
        const redirectTimeout = setTimeout(() => {
            history.push('/');
        }, 5000); // Redirect after 5 seconds

        return () => clearTimeout(redirectTimeout);
    }, [history]);

    return (
        <div>
            <h2>Thank you for completing the interview!</h2>
            <p>You will be redirected to the home page shortly.</p>
        </div>
    );
}

export default InterviewCompletion;
