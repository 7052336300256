import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function ChatbotCard({ chatbot }) {
    const numSessionsCompleted = chatbot.participantSessions.length;
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyLink = () => {
        const chatbotLink = `${process.env.REACT_APP_CHAT_PLATFORM_URL}/chat/${chatbot._id}`;

        navigator.clipboard.writeText(chatbotLink)
            .then(() => {
                console.log('Chatbot link copied to clipboard:', chatbotLink);
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false); // Hide the message after 2 seconds
                }, 2000);
            })
            .catch(error => {
                console.error('Failed to copy chatbot link to clipboard:', error);
                setIsCopied(false);
            });
    };

    return (
        <div className="chatbot-card bg-card-BG rounded-md text-black mt-4 float-right drop-shadow-none hover:drop-shadow-2xl border border-solid border-card-BG">
            <Link className="z-0 relative block float-left w-full" to={`/interviews/${chatbot._id}`}>
                <div className='p-8'>
                    <h3 className='text-2xl mb-2'>{chatbot.name}</h3>
                    <p>Sessions: {numSessionsCompleted}</p>
                    <div className='p-2 w-full mt-2 clear-both float-right w-full relative text-right align-middle'>
                        <p className='text-right'>
                            <span className='float-right'>
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3726 11.3138V13.3079L19.271 13.315L10.6655 21.9205L12.0797 23.3347L20.6852 14.7292L20.6923 22.6276H22.6863V11.3138H11.3726Z" fill="#121315"/>
                                </svg>
                            </span>
                        </p>
                    </div>
                </div>
            </Link>
            <div className='w-full relative block text-center float-left w-full p-2'>
                <button className='block relative z-50 p-2 clear-both w-full rounded-md border border-solid border-mint' onClick={handleCopyLink}>
                    <span className='text-canarySecondary'>Copy Share Link</span>
                </button>
                {isCopied && (
                    <span className="text-sm text-green-500">Link copied!</span>
                )}
            </div>
        </div>
    );
}

export default ChatbotCard;
