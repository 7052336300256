import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useHistory } from 'react-router-dom';
import PersonaCard from './PersonaCard';
import Modal from '../Modal';
import Spinner from '../Spinner';
import Alert from '../Alert';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

function InterviewDetail() {
    const { interviewId } = useParams();
    const [interview, setInterview] = useState(null);
    const [completedSessions, setCompletedSessions] = useState(0);
    const [processedTranscripts, setProcessedTranscripts] = useState('');
    const [personas, setPersonas] = useState([]);
    const [insights, setInsights] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [showAllKeywords, setShowAllKeywords] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const history = useHistory();

    const chartInitialState = {
        labels: [],
        datasets: [{
            label: '',
            data: [],
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            borderColor: 'rgba(0, 0, 0, 0.1)',
            borderWidth: 1
        }]
    };

    const [ageData, setAgeData] = useState(chartInitialState);
    const [countryData, setCountryData] = useState(chartInitialState);
    const [occupationData, setOccupationData] = useState(chartInitialState);

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/interviews/${interviewId}/details`)
            .then(response => {
                if (isMounted) {
                    setInterview(response.data);
                    setCompletedSessions(response.data.completedSessions);
                    if (response.data.insights) {
                        setInsights(response.data.insights);
                    }
                }
            })
            .catch(error => {
                console.error('Failed to fetch interview details:', error);
            });

        // Fetch personas
        axios.get(`/api/interviews/${interviewId}/personas`)
            .then(response => {
                if (isMounted) {
                    setPersonas(response.data);
                }
            })
            .catch(error => {
                console.error('Failed to fetch personas:', error);
            });

        return () => { isMounted = false; };
    }, [interviewId]);

    useEffect(() => {
        axios.get(`/api/interviews/${interviewId}/sessions/processed`)
            .then(response => {
                const allMessages = response.data.map(session => session.messages.join(' ')).join('\n');
                setProcessedTranscripts(allMessages);
            })
            .catch(error => {
                console.error('Failed to fetch processed sessions:', error);
            });
    }, [interviewId]);

    useEffect(() => {
        axios.get(`/api/interviews/${interviewId}/sessions/demographics`)
            .then(response => {
                processDemographics(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch demographic data:', error);
            });
    }, [interviewId]);

    const processDemographics = (data) => {
        if (data && data.ageRanges && data.countries && data.occupations) {
            setAgeData({
                labels: Object.keys(data.ageRanges),
                datasets: [{
                    label: 'Age Range',
                    data: Object.values(data.ageRanges),
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                }]
            });

            setCountryData({
                labels: Object.keys(data.countries),
                datasets: [{
                    label: 'Country',
                    data: Object.values(data.countries),
                    backgroundColor: 'rgba(153, 102, 255, 0.6)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderWidth: 1,
                }]
            });

            setOccupationData({
                labels: Object.keys(data.occupations),
                datasets: [{
                    label: 'Occupation',
                    data: Object.values(data.occupations),
                    backgroundColor: 'rgba(255, 159, 64, 0.6)',
                    borderColor: 'rgba(255, 159, 64, 1)',
                    borderWidth: 1,
                }]
            });
        }
    };

    const handleCopyLink = () => {
        const chatbotLink = `${process.env.REACT_APP_CHAT_PLATFORM_URL}/chat/${interviewId}`;
        navigator.clipboard.writeText(chatbotLink)
            .then(() => {
                console.log('Chatbot link copied to clipboard:', chatbotLink);
                setCopySuccess('Link copied to clipboard!');
                setTimeout(() => setCopySuccess(''), 2000);
            })
            .catch(error => {
                console.error('Failed to copy chatbot link to clipboard:', error);
                setCopySuccess('Failed to copy link.');
                setTimeout(() => setCopySuccess(''), 2000);
            });
    };


    // Render function for Copy Link message
    const renderCopyLinkMessage = () => {
        if (copySuccess) {
            return (
                <div className="text-sm mt-2 text-green-500">
                    {copySuccess}
                </div>
            );
        }
        return null;
    };
    const generatePersona = async () => {
        setLoading(true);
        try {
            const response = await axios.post('/api/generate-framework', { combinedText: processedTranscripts });
            const personaData = response.data;
            await savePersona(personaData);
            setAlert({ message: 'Persona generated and saved successfully!', type: 'success' });
        } catch (error) {
            console.error('Failed to generate persona:', error);
            setAlert({ message: 'Failed to generate persona', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const generateInsights = async () => {
        setLoading(true);
        try {
            const response = await axios.post('/api/generate-insights', {
                combinedText: processedTranscripts,
                objective: interview.objective
            });
            const insightsData = response.data;
            setInsights(insightsData.insights);
            await saveInsights(insightsData.insights);

            setAlert({ message: 'Insights generated and saved successfully!', type: 'success' });
        } catch (error) {
            console.error('Failed to generate insights:', error);
            setAlert({ message: 'Failed to generate insights', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const savePersona = async (personaData) => {
        try {
            const saveResponse = await axios.post('/api/save-persona', {
                ...personaData,
                interviewId,
                transcriptIds: []
            });
            setPersonas([...personas, saveResponse.data]);
        } catch (error) {
            console.error('Failed to save persona:', error);
            setAlert({ message: 'Failed to save persona', type: 'error' });
        }
    };

    const saveInsights = async (insightsData) => {
        try {
            const response = await axios.post(`/api/interviews/save-insights/${interviewId}`, { insights: insightsData });
            console.log('Insights saved:', response.data);
        } catch (error) {
            console.error('Failed to save insights:', error);
            setAlert({ message: 'Failed to save insights', type: 'error' });
        }
    };

    const deletePersona = async (personaId) => {
        setLoading(true);
        try {
            await axios.delete(`/api/interviews/personas/${personaId}`);
            setPersonas(personas.filter(persona => persona._id !== personaId));
            setAlert({ message: 'Persona deleted successfully', type: 'success' });
        } catch (error) {
            console.error('Failed to delete persona:', error);
            setAlert({ message: 'Failed to delete persona', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeAlert = () => {
        setAlert({ message: '', type: '' });
    };

    const handleShowAllKeywords = () => {
        setShowAllKeywords(!showAllKeywords);
    };

    const renderKeywords = () => {
        if (!insights.keywords || insights.keywords.length === 0) return <p>No keywords available.</p>;

        const sortedKeywords = [...insights.keywords].sort((a, b) => b.frequency - a.frequency);
        const keywordsToDisplay = showAllKeywords ? sortedKeywords : sortedKeywords.slice(0, 10);

        return (
            <div>
                <table className="min-w-full bg-white">
                    <thead>
                        <tr>
                            <th className="py-2">Keyword</th>
                            <th className="py-2">Frequency</th>
                        </tr>
                    </thead>
                    <tbody>
                        {keywordsToDisplay.map((keyword, index) => (
                            <tr key={index}>
                                <td className="border px-4 py-2">{keyword.keyword}</td>
                                <td className="border px-4 py-2">{keyword.frequency}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {insights.keywords.length > 10 && (
                    <button 
                        onClick={handleShowAllKeywords} 
                        className="mt-2 text-indigo-600 hover:text-indigo-800"
                    >
                        {showAllKeywords ? 'Show Less' : 'Show All'}
                    </button>
                )}
            </div>
        );
    };

    const handleDeleteInterview = () => {
        setDeleteModalOpen(true);
    };

    const confirmDeleteInterview = async () => {
        setLoading(true);
        try {
            await axios.delete(`/api/interviews/${interviewId}/delete`);
            setAlert({ message: 'Interview deleted successfully!', type: 'success' });
            history.push(`/projects/${interview.projectId._id}/detail`);
        } catch (error) {
            console.error('Failed to delete interview:', error);
            setAlert({ message: 'Failed to delete interview', type: 'error' });
        } finally {
            setLoading(false);
            setDeleteModalOpen(false);
        }
    };

    if (!interview) {
        return <div>Loading interview details...</div>;
    }

    return (
        <div className='container z-0 mx-auto text-mint p-12 mt-12 mb-12 bg-BGGrey-DARK rounded-md mt-4 border-solid border border-BGGrey-BORDER '>
            <div>
                <Link to={`/projects/${interview.projectId._id}/detail`}>Back to Project</Link>
                <div>
                    <span className='font-extrabold text-xs'>INTERVIEW</span>
                    <h1 className='font-extrabold text-2xl'>{interview.title}</h1>
                </div>
                <div>
                    <p>Linked to Project: {interview.projectName}</p>
                    <p>Number of Completed Sessions: {completedSessions}</p>
                    <p>Script used: <button onClick={openModal} className="underline text-blue-600 hover:text-blue-800">View Script</button></p>
                </div>
                <button className='block z-50 p-2 clear-both w-full rounded-md bg-canaryGreen-DARK mt-6 mb-6' onClick={handleCopyLink}>
                    <span className='text-canary'>Copy Share Link</span>
                </button>
                {renderCopyLinkMessage()} 
                <button 
                    onClick={handleDeleteInterview} 
                    disabled={loading} 
                    className="mr-2 bg-red-500 text-white py-2 px-4 rounded"
                >
                    {loading ? 'Deleting...' : 'Delete Interview'}
                </button>
            </div>
            <div>
                {alert.message && (
                    <Alert message={alert.message} type={alert.type} onClose={closeAlert} />
                )}
                <button
                    className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
                    onClick={generatePersona} disabled={loading}>
                    {loading ? <Spinner /> : 'Generate Persona'}
                </button>
                <button 
                    className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
                    onClick={generateInsights} disabled={loading}>
                    
                    {loading ? <Spinner /> : 'Generate Insights'}
                </button>

                <h2 className='clear-both mt-6 bt-4 font-bold text-lg'>Generated Personas</h2>
                {personas.length > 0 ? (
                    <div className="grid grid-cols-4 gap-4">
                        {personas.map((persona, index) => (
                            <PersonaCard key={index} persona={persona} onDelete={deletePersona} />
                        ))}
                    </div>
                ) : (
                    <p>No personas generated yet.</p>
                )}

                {insights && (
                    <div className="mt-8">
                        <h2 className='font-bold text-lg'>Insights</h2>

                        <div  className='position-relative display-block w-full clear-both mb-8'>
                            <h3 className='font-bold'>Demographics</h3>
                            <div className=' grid grid-cols-2 float-left'>
                                <div className="my-4">
                                    <h4 className='font-bold'>Age Range</h4>
                                    <Bar data={ageData} />
                                </div>
                                <div className="my-4">
                                    <h4 className='font-bold'>Occupations</h4>
                                    <Bar data={occupationData} />
                                </div>
                                <div className="my-4">
                                    <h4 className='font-bold'>Countries</h4>
                                    <Bar data={countryData} />
                                </div>
                            </div>
                        </div>
                        <div  className='position-relative display-block w-full clear-both mb-8'>
                            <h3 className='font-bold'>Trends</h3>
                            <p>{insights.trends}</p>
                        </div>
                        <div  className='position-relative display-block w-full clear-both mb-8'>
                            <h3 className='font-bold'>Keywords</h3>
                            {renderKeywords()}
                        </div>
                        <div className='position-relative display-block w-full clear-both mb-8'>
                            <h3 className='font-bold'>Topics</h3>
                            <ul className='float-left w-full mb-8'>
                                {insights.topics.map((topic, index) => (
                                    <li className="p-3 bg-cyan-500 rounded-full float-left mr-2" key={index}>{topic}</li>
                                ))}
                            </ul>
                        </div>
                        <div className='w-full clear-both mb-8'>
                            <h3 className='font-bold'>Sentiments</h3>
                            <p>{insights.sentiments.description}</p>
                            <p>Score: {insights.sentiments.score}</p>
                        </div>
                        <div  className='position-relative display-block w-full clear-both mb-8'>
                            <h3 className='font-bold'>Suggestions</h3>
                            <ul>
                                {insights.suggestions.map((suggestion, index) => (
                                    <li key={index}>{suggestion}</li>
                                ))}
                            </ul>
                        </div>

                        
                    </div>
                )}
            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal} className="w-full">
                <h2 className='font-bold text-lg mb-4'>Script</h2>
                <p dangerouslySetInnerHTML={{ __html: interview.script.replace(/\n/g, '<br>') }}></p>
            </Modal>

            <Modal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} className="w-full">
                <h2 className='font-bold text-lg mb-4'>Warning - Delete Interview</h2>
                <p>You are about to delete this interview. This action cannot be undone and will delete all related sessions and personas. Are you sure?</p>
                <div className='mt-4'>
                    <button 
                        onClick={confirmDeleteInterview} 
                        className="mr-2 bg-red-500 text-white py-2 px-4 rounded"
                    >
                        Delete Interview
                    </button>
                    <button 
                        onClick={() => setDeleteModalOpen(false)} 
                        className="bg-gray-300 text-black py-2 px-4 rounded"
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default InterviewDetail;
