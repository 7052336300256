import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

function InterviewSetup({ auth, match }) {
    const [loading, setLoading] = useState(false);
    const { projectId } = match.params;

    const handleScriptUpload = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('script', file);

        setLoading(true);
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };
            const response = await axios.post(`/api/projects/${projectId}/interviews`, formData, config);
            console.log('Interview created:', response.data);
            setLoading(false);
            // Redirect or handle UI update
        } catch (error) {
            console.error('Error uploading script:', error);
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Upload Interview Script</h1>
            <input type="file" onChange={handleScriptUpload} disabled={loading} />
        </div>
    );
}

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default connect(mapStateToProps)(InterviewSetup);
