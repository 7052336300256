import React from 'react';

const Spinner = () => {
    const spinnerContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    };

    const loadingSpinnerStyle = {
        border: '5px solid #f3f3f3',
        borderTop: '5px solid #3498db',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        animation: 'spin 2s linear infinite'
    };

    const spinnerTextStyle = {
        marginTop: '10px',
        color: '#3498db',
        fontSize: '1em'
    };

    return (
        <div style={spinnerContainerStyle}>
            <style>
                {`
                    @keyframes spin { 
                        0% { transform: rotate(0deg); } 
                        100% { transform: rotate(360deg); }
                    }
                `}
            </style>
            <div style={loadingSpinnerStyle}></div>
            <p style={spinnerTextStyle}>Analyzing...</p>
        </div>
    );
};

export default Spinner;
