import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PriceCard from './cards/PriceCard';
import { fetchUser } from '../actions'; 

const Pricing = ({ auth, history }) => {
    const dispatch = useDispatch();  // Get the dispatch function
    const [prices, setPrices] = useState([]);

    useEffect(() => {
        dispatch(fetchUser());  // Dispatch the fetchUser action to fetch user data
      }, [dispatch]);

    useEffect(() => {
        fetchPrices();
    }, []);

    const fetchPrices = async () => {
        const {data} = await axios.get("/api/prices");
        console.log("Fetching prices: ", data);
        setPrices(data);
    };

    const handleClick = async (e, price) => {
        e.preventDefault();
        if(auth){
            const {data} = await axios.post('/api/create-subscription', {
                priceID: price.id,
                productID: price.product  // Pass the product ID along with the price ID
            });
            window.open(data.url);
        } else {
            history.push("/register");
        }
    };

    return (
        <div className='relative grid grid-cols-12 text-mint z-0'>
            <div className='col-span-12'>
                <h1>Explore the right plan for you</h1>
                <p>Choose a plan</p>
            </div>
            {prices && prices.map((price) => 
                <PriceCard 
                    key={price.id}
                    price={price}
                    handleSubscription={(e) => handleClick(e, price)}
                    userSubscription={auth.subscription}
                />
            )}
        </div>
    )
};

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default connect(mapStateToProps)(Pricing);
