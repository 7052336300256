// reducers/interviewReducer.js
import { RESET_INTERVIEW_DATA, SET_INTERVIEW_DATA } from '../actions/types';

const INITIAL_STATE = {
  interviewData: null,
  // Define other interview state properties here
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_INTERVIEW_DATA:
      return { ...state, interviewData: action.payload };
    case RESET_INTERVIEW_DATA:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
