import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; // Import withRouter

function ProjectCreation({ auth, history }) {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post('/api/projects', { name });
            console.log('Project created:', response.data);
            setLoading(false);
            
            // Redirect to project detail page after successful creation
            history.push(`/Project/${response.data._id}`); // Assuming response contains the new project ID
        } catch (error) {
            console.error('Error creating project:', error);
            setLoading(false);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label>
                    Project Name:
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </label>
                <button type="submit" disabled={loading}>
                    {loading ? 'Creating...' : 'Create Project'}
                </button>
            </form>
        </div>
    );
}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default connect(mapStateToProps)(withRouter(ProjectCreation)); // Wrap component with withRouter
