import React from 'react';

const Register = () => {
   
    return (
        <div className='relative grid grid-cols-12 text-white z-1'>
            
            <a href="/login">
                <button
                        className='items-center justify-center w-full px-8 py-3 mb-4 overflow-hidden border border-slate-700  rounded-md cursor-pointer'
                >
                       Get started
                    
                </button>
             </a>
        </div>
    )

};

export default Register;