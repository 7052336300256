import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchUser, updateSubscription } from '../actions';



const Profile = ({ auth }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const response = await axios.get('/api/subscription-status');
        dispatch(updateSubscription(response.data[0])); // Assuming the first item is the most recent subscription
        setLoading(false); // Set loading to false after the data is fetched and dispatched
      } catch (error) {
        console.error('Error fetching subscription data:', error);
        setLoading(false); // Ensure loading is set to false even if there is an error
      }
    };
  
    fetchSubscriptionData();
  }, [dispatch]);
  

  if (!auth || loading) {
    return <div>Loading...</div>;
  }
  

  console.log('Auth data:', auth); // Check what is actually in the auth object

  const subscription = auth.subscription;

  console.log('Subscription: ', subscription)
  console.log('Auth Subscription: ', auth.subscription)

  const manageSubscriptions = async () => {
    const { data } = await axios.get("/api/manage-subscription");
    window.open(data);
  };

  const handleCancelSubscription = async () => {
    const { data } = await axios.post('/api/cancel-subscription');
    alert(data.message);
  };

  const handleChangeSubscription = async (newPlanId) => {
    const { data } = await axios.post('/api/change-subscription', { newPlanId });
    alert(data.message);
  };

  return (
    <div className="profile-container mx-auto z-0 relative bg-BGGrey-DARK text-mint">
      {auth.profilePic ? (
        <img src={auth.profilePic} alt="Profile" className="profile-picture" />
      ) : (
        <div className="fallback-profile-picture">
          {auth.firstName[0]}{auth.lastName[0]}
        </div>
      )}
      <h2>{auth.firstName} {auth.lastName}</h2>
      <p>Email: {auth.email}</p>
      <div>
        <h2>Current limits</h2>
        <div>
          <p>Current Interview usage: {auth.currentInterviews} </p>
          <p>Current Session usage: {auth.currentSessions}</p>
          <p>Current Interview limits:{auth.interviewLimits} </p>
          <p>Current Session limits: {auth.sessionLimits}</p>
        </div>
        <h2>Subscription</h2>
        {auth.subscription ? (
          <div>
           <p>Plan: {auth.subscription.plan.name}</p>
           <p>Status: {auth.subscription.status}</p>
            <p>Price: £{(auth.subscription.plan.amount / 100).toFixed(2)}</p>
            <p>Renews: Every {auth.subscription.plan.interval}</p>
            <p>Card: **** **** **** {auth.subscription.default_payment_method.card.last4}</p>
            <p>Current period ends: {moment.unix(auth.subscription.currentPeriodEnd).format("MMMM Do YYYY")}</p>
            {auth.subscription.canceled_at && (
              <p>Canceled at: {moment.unix(auth.subscription.canceled_at).format("MMMM Do YYYY")}</p>
            )}
            <button onClick={handleCancelSubscription}>Cancel Subscription</button>
            <button onClick={() => handleChangeSubscription('new_plan_id')}>Change Plan</button>
            <button><a href="/api/logout">Logout</a></button>
            <button 
              onClick={manageSubscriptions}
              className='items-center justify-center w-full px-8 py-3 mb-4 overflow-hidden border border-slate-700  rounded-md cursor-pointer'>
                
                Manage subscription
            </button>
          </div>
        ) : (
          <div>
            <p>No active subscription</p>
            <Link to="/pricing">View Pricing</Link>
          </div>
        )}
      </div>


    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps)(Profile);
