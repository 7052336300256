// SimulatedInterviewDetailPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function SimulatedInterviewDetailPage() {
    const { sessionId } = useParams();
    const [sessionDetails, setSessionDetails] = useState(null);

    useEffect(() => {
        // Fetch session details
        axios.get(`/api/simulated-interviews/${sessionId}/details`)
            .then(response => setSessionDetails(response.data))
            .catch(error => console.error('Failed to fetch session details:', error));
    }, [sessionId]);

    if (!sessionDetails) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mx-auto">
            <h1 className="text-2xl font-bold">Simulated Interview Detail</h1>
            <h2 className="text-xl font-semibold">{sessionDetails.topic}</h2>
            <p>Date: {new Date(sessionDetails.date).toLocaleString()}</p>
            <h3 className="text-lg font-semibold mt-4">Messages</h3>
            <ul>
                {sessionDetails.messages.map((msg, index) => (
                    <li key={index} className={`message ${msg.sender}`}>
                        <p>{msg.text}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default SimulatedInterviewDetailPage;
