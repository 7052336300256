// PersonaCard.js
import React from 'react';
import { Link } from 'react-router-dom';

function PersonaCard({ persona, onDelete }) {
    return (
        <div className="persona-card bg-white p-4 rounded-lg shadow-md">
             <Link to={`/personas/${persona._id}`}>
                <div>
                    <h2><strong>Name:</strong> {persona.name}</h2>
                    <p><strong>Description:</strong> {persona.description}</p>
                    View Details

                    <button
                        onClick={() => onDelete(persona._id)}
                        className="mt-4 bg-red-500 text-white py-2 px-4 rounded"
                    >
                        Delete
            </button>
                </div>
            </Link>
           
        </div>
    );
}

export default PersonaCard;
