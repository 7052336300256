import { FETCH_USER, UPDATE_USER, UPDATE_SUBSCRIPTION } from '../actions/types';

function authReducer(state = null, action) {
  switch (action.type) {
    case FETCH_USER:
      return action.payload || false;
    case UPDATE_USER:
      return { ...state, ...action.payload };
    case UPDATE_SUBSCRIPTION:
      return { ...state, subscription: action.payload };
    default:
      return state;
  }
}

export default authReducer;