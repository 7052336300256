import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import UserInformationForm from './chatComps/UserInformationForm';
import ConsentScreen from './chatComps/ConsentScreen';
import ChatInterface from './chatComps/ChatInterface';
import FinalScreen from './chatComps/FinalScreen';
import LogoBlack from '../icons/logoBlack';
import LoadingBar from './elements/LoadingBar';

function ChatSession() {
    const { interviewId } = useParams();
    const history = useHistory();
    const [sessionId, setSessionId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [error, setError] = useState(null);
    const [chatReady, setChatReady] = useState(false); // New state to indicate chat readiness
    const [chatStarted, setChatStarted] = useState(false);
    const [interviewComplete, setInterviewComplete] = useState(false);
    const [consentGiven, setConsentGiven] = useState(false);
    const [isConsentChecked, setIsConsentChecked] = useState(false);
    const [userInfoCollected, setUserInfoCollected] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [inputText, setInputText] = useState('');
    const [loading, setLoading] = useState(false);
    const [organization, setOrganization] = useState('');
    const [incentiveCode, setIncentiveCode] = useState(null);
    const [initializing, setInitializing] = useState(false); // Changed initializing state
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const fetchOrganizationName = async () => {
            try {
                const response = await axios.get(`/api/interviews/${interviewId}/details`);
                const { organizationName } = response.data;
                setOrganization(organizationName);
            } catch (error) {
                console.error('Failed to fetch organization name:', error);
                setError('Failed to fetch organization name');
            }
        };
        fetchOrganizationName();
    }, [interviewId]);

    useEffect(() => {
        const fetchInterviewDetails = async () => {
            try {
                const response = await axios.get(`/api/interviews/${interviewId}`);
                setUserInfo({ ...response.data });
            } catch (error) {
                console.error('Failed to fetch interview details:', error);
                setError('Failed to fetch interview details');
            }
        };

        if (consentGiven && userInfoCollected) {
            fetchInterviewDetails();
        }
    }, [interviewId, consentGiven, userInfoCollected]);

    // Initiate chat session as soon as consent is given
    useEffect(() => {
        if (consentGiven && !chatStarted) {
            initiateChatSession();
        }
    }, [consentGiven]);

    const handleUserInformationSubmit = (userInfo) => {
        setUserInfo(userInfo);
        setUserInfoCollected(true);
    };

    const handleBeginChat = () => {
        if (isConsentChecked) {
            setConsentGiven(true);
        }
    };

    const initiateChatSession = async () => {
        setInitializing(true);
        setProgress(0);

        // Simulate loading progress with specific delays
        setProgress(10);
        setTimeout(() => setProgress(50), 1000);
        setTimeout(() => setProgress(70), 2000); // Pause at 50% for 1 second
        setTimeout(() => setProgress(90), 3000); // Pause at 75% for 2 seconds

        try {
            const response = await axios.post(`/api/sessions/${interviewId}`, { consentGiven, userInfo });
            setSessionId(response.data.sessionId);

            // Set assistant's initial response once received
            setMessages([{ text: response.data.initialMessage || 'Welcome to the interview!', sender: 'bot' }]);
            setChatStarted(true);
            setChatReady(true); // Chat is now ready to display after form completion
        } catch (error) {
            setError('Failed to start chat session');
        } finally {
            setTimeout(() => setInitializing(false), 3500); // Smooth transition after completion
        }
    };

    const sendMessage = async (messageText) => {
        if (!sessionId) {
            setError('Session ID is null. Cannot send message.');
            return;
        }

        setLoading(true);

        setMessages((prevMessages) => [
            ...prevMessages,
            { text: messageText, sender: 'user' }
        ]);

        try {
            const response = await axios.post(`/api/sessions/${interviewId}/${sessionId}/send`, { message: messageText });
            const botResponse = response.data.botResponse;
            
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: botResponse, sender: 'bot' }
            ]);

        } catch (error) {
            console.error('Failed to send message:', error);
            setError('Failed to send message');
        } finally {
            setLoading(false);
            setInputText('');
        }
    };

    const completeInterview = async () => {
        if (!sessionId) {
            setError('No session to complete.');
            return;
        }
        try {
            const response = await axios.post(`/api/complete/${interviewId}`, { sessionId, messages });
            if (response.status === 200 && response.data.incentiveCode) {
                setIncentiveCode(response.data.incentiveCode);
                setInterviewComplete(true);
            } else {
                setError('Failed to complete interview.');
            }
        } catch (error) {
            console.error('Failed to complete interview:', error);
            setError('Failed to complete interview');
        }
    };
    
    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!consentGiven) {
        return (
            <div className="chat-interface container mx-auto p-4 md:w-[37rem] sm:w-full">
                <div className=''>
                    <LogoBlack />
                </div>
                <ConsentScreen 
                    isConsentChecked={isConsentChecked}
                    setIsConsentChecked={setIsConsentChecked}
                    handleBeginChat={handleBeginChat}
                    organization={organization}
                />
            </div>
        );
    }

    if (consentGiven && !userInfoCollected) {
        return (
            <div className="chat-interface container mx-auto p-4 md:w-[37rem] sm:w-full">
                <div className=''>
                    <LogoBlack />
                </div>
                <UserInformationForm onSubmit={handleUserInformationSubmit} />
            </div>
        );
    }

    if (interviewComplete) {
        return <FinalScreen incentiveCode={incentiveCode} sessionId={sessionId} />;
    }

    // Show central loading message if assistant is initializing and userInfo not yet collected
    if (initializing && (!userInfoCollected || !chatReady)) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-center">
                    <LogoBlack />
                    <p className="text-lg mt-4">Starting your interview with {organization}…</p>
                    <LoadingBar progress={progress} />
                </div>
            </div>
        );
    }

    return (
        <ChatInterface
            messages={messages}
            inputText={inputText}
            setInputText={setInputText}
            sendMessage={sendMessage}
            completeInterview={completeInterview}
            loading={loading} 
        />
    );
}

export default ChatSession;
