import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { fetchUser } from '../actions';
import { useHistory } from 'react-router-dom';

const OnboardingForm = ({ auth, fetchUser }) => {
  const [userRole, setUserRole] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [userGoals, setUserGoals] = useState('');
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const loadUserData = async () => {
      await fetchUser();
      setLoading(false);
    };
    loadUserData();
  }, [fetchUser]);

  useEffect(() => {
    if (auth) {
      setUserRole(auth.userRole || '');
      setCompanyName(auth.companyName || '');
      setCompanySize(auth.companySize || '');
      setUserGoals(auth.userGoals || '');
    }
  }, [auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/user/onboarding', {
        userRole,
        companyName,
        companySize,
        userGoals
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        history.push('/projects');
      }
    } catch (error) {
      console.error('Failed to submit onboarding form:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!auth) {
    history.push('/login');
    return null;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Complete Your Profile</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Role</label>
          <select
            value={userRole}
            onChange={(e) => setUserRole(e.target.value)}
            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm"
          >
            <option value="">Select your role</option>
            <option value="Researcher">Researcher</option>
            <option value="Designer">Designer</option>
            <option value="Marketer">Marketer</option>
            <option value="Product manager">Product manager</option>
            <option value="Project manager">Project manager</option>
            <option value="Founder / CEO">Founder / CEO</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Company Name (Optional)</label>
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Company Size (Optional)</label>
          <select
            value={companySize}
            onChange={(e) => setCompanySize(e.target.value)}
            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm"
          >
            <option value="">Select company size</option>
            <option value="freelancer">Freelancer</option>
            <option value="1-10 employees">1-10 employees</option>
            <option value="11-20 employees">11-20 employees</option>
            <option value="21-50 employees">21-50 employees</option>
            <option value="50-100 employees">50-100 employees</option>
            <option value="100+ employees">100+ employees</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">What would you like to do?</label>
          <select
            value={userGoals}
            onChange={(e) => setUserGoals(e.target.value)}
            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm"
          >
            <option value="">Select your goal</option>
            <option value="Run research projects">Run research projects</option>
            <option value="Improve their research skills">Improve their research skills</option>
          </select>
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps, { fetchUser })(OnboardingForm);
