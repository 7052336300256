import React, { useState } from 'react';
import axios from 'axios';

function FinalScreen({ incentiveCode, sessionId }) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleEmailSubmit = async () => {
        if (!email) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            const response = await axios.post(`/api/complete/${sessionId}/claim`, { email });
            if (response.status === 200) {
                setSuccess(true);
                setError(null);
            }
        } catch (err) {
            setError('Failed to submit email. Please try again.');
        }
    };

    return (
        <div className="relative md:w-[37rem] sm:w-full space-y-6 p-6 mt-8 md:mt-12 sm:mt-8 mb-16 bg-white rounded-lg shadow-md mx-auto w-full">
            <h1>Thank you for completing the interview!</h1>
            {incentiveCode && (
                <div>
                    <p>Your incentive code: <strong>{incentiveCode}</strong></p>
                    <p>Please enter your email address to claim your incentive.</p>
                </div>
            )}
            <input 
                className="mt-1 block w-full px-4 py-4 bg-BGGrey p-4 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="email" 
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">Email submitted successfully!</p>}
            <button 
                onClick={handleEmailSubmit}
                className="w-full flex bg-BGGrey-DARK justify-center mt-8 px-4 py-4  border border-transparent rounded-full shadow-sm text-sm font-medium text-canary bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            
            >
                Submit Email
            </button>
        </div>
    );
}

export default FinalScreen;
