// Dropdown.js
import React, { Component } from 'react';

class Dropdown extends Component {
  state = {
    isOpen: false
  };

  toggleDropdown = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  closeDropdown = (e) => {
    if (this.dropdown && !this.dropdown.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.closeDropdown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closeDropdown);
  }

  render() {
    const dropdownStyle = {
      left: this.props.leftPosition || '0%',
      backgroundColor: this.props.backgroundColor || 'bg-white',  
      width: this.props.width || '100px',
      textAlign: this.props.textAlign || 'left',
    };
    return (
      <div ref={(node) => { this.dropdown = node; }} className="relative">
        <button onClick={this.toggleDropdown}>
          {this.props.toggleContent}
        </button>
        {this.state.isOpen && (
          <div style={dropdownStyle} className={`dropdown-content absolute top-full mt-2 p-8 z-999 ${dropdownStyle.backgroundColor} border border-gray-300 rounded shadow-lg`}>
            {this.props.dropdownContent}
          </div>
        )}
      </div>
    );
  }
}

export default Dropdown;
