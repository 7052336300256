import React from 'react';

const Landing = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>
        Emaily!
      </h1>
      Collect feedback form your users
    </div>
  );
};

export default Landing;
