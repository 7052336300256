import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import ChatbotCard from './ChatbotCard';
import { Link, useHistory } from 'react-router-dom';
import Modal from '../Modal';
import Alert from '../Alert';

function ProjectDetailPage({ auth, match }) {
    const [project, setProject] = useState(null);
    const [chatbots, setChatbots] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const projectId = match.params.projectId;

        axios.get(`/api/projects/${projectId}`)
            .then(response => {
                setProject(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch project details:', error);
                setAlert({ message: 'Failed to fetch project details', type: 'error' });
            });

        axios.get(`/api/projects/${projectId}/chatbots`)
            .then(response => {
                setChatbots(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch chatbots for project:', error);
                setAlert({ message: 'Failed to fetch chatbots', type: 'error' });
            });
    }, [match.params.projectId]);

    const handleCreateChatbotClick = async () => {
        if (auth.currentInterviews >= auth.interviewLimits) {
            setAlert({ message: 'You have reached your interview limit. Please upgrade your subscription.', type: 'error' });
        } else {
            history.push(`/projects/${match.params.projectId}/chatbot/create`);
        }
    };

    const deleteProject = async () => {
        setLoading(true);
        try {
            await axios.delete(`/api/projects/${match.params.projectId}`);
            setAlert({ message: 'Project deleted successfully!', type: 'success' });
            history.push('/projects');
        } catch (error) {
            console.error('Failed to delete project:', error);
            setAlert({ message: 'Failed to delete project', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleConfirmDelete = () => {
        setIsModalOpen(false);
        deleteProject();
    };

    return (
        <div className='container mx-auto'>
            <div className='mb-6 mt-4'>
                {project && (
                    <div className='w-full relative block'>
                        <div className='text-BGGrey-BORDER text-left float-left w-full block relative clear-both text-xs'>
                            <Link className="float-left" to={`/projects`}>All projects</Link>
                            <span className="float-left ml-2 mt-1">
                                {/* SVG here */}
                            </span>
                            <span className='text-lightGrey float-left ml-2 '>{project.name} detail</span>
                        </div>
                        <div className='relative block w-full float-left clear-both mt-8'>
                            <div className='mb-12 text-black-TEXT float-left block relative clear-both'>
                                <p className='mb-2'>{project.organization}</p>
                                <h2 className='text-4xl mb-4'>{project.name}</h2>
                                <p className='text-sm'>Created At: {new Date(project.createdAt).toLocaleDateString()}</p>
                            </div>
                            <div className='float-right'>
                                {alert.message && <Alert message={alert.message} type={alert.type} />}
                                <button onClick={handleCreateChatbotClick} className="bg-canary hover:bg-yellow-600 text-black py-2 px-4 rounded-full shadow-lg">
                                    Create New Chatbot
                                </button>
                                <button onClick={handleDeleteClick} disabled={loading} className="ml-2 bg-red-500 text-alertRed py-2 px-4 rounded">
                                    {loading ? 'Deleting...' : 'Delete Project'}
                                </button>
                            </div>
                        </div>
                        {alert.message && (
                            <div className={`alert ${alert.type === 'error' ? 'alert-danger' : 'alert-success'}`}>
                                {alert.message}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <h2 className='w-full clear-both relative block text-black-TEXT'>Interviews</h2>
            <div className=''>
                <div className="chatbot-list grid grid-col-4 gap-4 md:grid-cols-4">
                    {chatbots.map(chatbot => (
                        <ChatbotCard key={chatbot._id} chatbot={chatbot} />
                    ))}
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <div className="p-6">
                    <h2 className="text-xl font-bold mb-4">Warning - Delete Project</h2>
                    <p className="mb-4">
                        You are about to delete a project. This will also delete all of your interviews and associated data. Are you sure?
                    </p>
                    <div className="flex justify-end">
                        <button onClick={handleCloseModal} className="bg-gray-300 text-gray-800 py-2 px-4 rounded mr-2">
                            Cancel
                        </button>
                        <button onClick={handleConfirmDelete} className="bg-red-500 text-white py-2 px-4 rounded">
                            Delete Project
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default connect(mapStateToProps)(ProjectDetailPage);
