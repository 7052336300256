// Alert.js
import React from 'react';

const Alert = ({ message, type, onClose }) => {
    const getAlertClass = () => {
        switch (type) {
            case 'success':
                return 'bg-green-100 border-green-400 text-green-700';
            case 'error':
                return 'bg-red-100 border-red-400 text-red-700';
            case 'info':
                return 'bg-blue-100 border-blue-400 text-blue-700';
            case 'warning':
                return 'bg-yellow-100 border-yellow-400 text-yellow-700';
            default:
                return 'bg-gray-100 border-gray-400 text-gray-700';
        }
    };

    return (
        <div className={`border-l-4 p-4 mb-4 ${getAlertClass()}`} role="alert">
            <p className="font-bold">{type.charAt(0).toUpperCase() + type.slice(1)}</p>
            <p>{message}</p>
            {onClose && (
                <button className="mt-2 text-sm underline text-blue-600 hover:text-blue-800" onClick={onClose}>
                    Close
                </button>
            )}
        </div>
    );
};

export default Alert;
