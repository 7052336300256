import { useAxiosClient } from "./useAxiosClient";

export const useAuthApi = () => {
    const { client } = useAxiosClient();

    const getCurrentUser = () => {
        return client().get('/current_user').then((response) => {
            return response.data;
        }).catch(error => {
            console.log('Auth Error ::: ', error);
        })
    }

    return { getCurrentUser }
}